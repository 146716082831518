<template>
    <div class="home">
        <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="自定义筛选时间" class="filter" name="1">
        <van-cell
          title="请选择开始时间"
          :value="filter.startVal.toString()"
          is-link
          @click="filter.startShow = true"
        />
        <van-cell
          title="请选择结束时间"
          :value="filter.endVal"
          is-link
          @click="filter.endShow = true"
        />
        <van-row class="text-center">
          <van-col span="12"
            ><van-button type="primary" size="small" round @click="resetData"
              >重置</van-button
            ></van-col
          >
          <van-col span="12">
            <van-button type="primary" size="small" round @click="filterData"
              >筛选</van-button
            ></van-col
          >
        </van-row>
      </van-collapse-item>
    </van-collapse>
    <div class="nav-char-custom">
      <div v-for="(item,index) in navlist" :key="index" class="wid_3">
          <div :class="{'block': true,'active': active == index ? true : false}" @click="selectTime(index)"><i :class="item.className"></i>{{ item.title }}</div>
      </div>
    </div>
    <div>
      <div v-if="chartLoading">
        <van-loading type="spinner" vertical color="#0094ff" >加载中</van-loading>
      </div>
      <div v-else>
        <div><canvas id="chartBattery"></canvas></div>
      </div>
    </div>
    <!-- 筛选时间段 -->
    <van-popup v-model="filter.startShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.start"
        type="datetime"
        title="选择开始时间"
        :filter="filterOption"
        @confirm="confromStartTime"
        @cancel="filter.startShow = false"
      />
    </van-popup>
    <van-popup v-model="filter.endShow" round position="bottom">
      <van-datetime-picker
        v-model="filter.end"
        type="datetime"
        title="选择结束时间"
        :filter="filterOption"
        @confirm="confromEndTime"
        @cancel="filter.endShow = false"
      />
    </van-popup>
    </div>
  </template>
  <script>
  import moment from "moment";
  const F2 = require("@antv/f2");
  export default {
    components: {
    
    },
    props: ['magbot'],
    data() {
      return {
        activeName: '',
        active:0,
        info:{
                devCode:'',
                devName:'',
                devStatus:'offline'
            },
        filter: {
            start: new Date(),
            end: new Date(),
            startVal: "",
            endVal: "",
            startShow: false,
            endShow: false,
      },
        navlist:[
        {className:'fa fa-line-chart',title:'日视图'},
        {className:'fa fa-line-chart',title:'周视图'},
        {className:'fa fa-line-chart',title:'月视图'}
      ],
      searchType:'day',
      chart0:null,
      loading:false,
      finished:false,
        chartLoading:true,
        alarmtotal:'',
        magbotVal:'',
        projectCode:'',
        chartData:[],
      }
    },
    watch: {
        magbot: function (val) {   
          this.magbotVal = val;   
        }
    },
    methods:{
        // 获取设备基础信息
        getBaseInfo(){
            this.info = window.sessionStorage.getItem('dev-info')? JSON.parse(window.sessionStorage.getItem('dev-info')) : {};
            window.document.title = this.info.devName;
        },
        // 时间选项过滤
    filterOption(type, options) {
      if (type === 'minute') {
        return options.filter((option) => option % 10 === 0);
      }
      return options;
    },
      // 确认开始时间
      confromStartTime(val){
        this.filter.startVal = moment(val).format("yyyy-MM-DD HH:mm:ss");
        this.filter.startShow = false;
      },
      // 确认结束时间
      confromEndTime(val){
        this.filter.endVal = moment(val).format("yyyy-MM-DD HH:mm:ss");
        this.filter.endShow = false;
      },
      // 重置
      resetData(){
        this.filter = {
        start: new Date(),
        end: new Date(),
        startVal: "",
        endVal: "",
        startShow: false,
        endShow: false,
      };
      },
      // 筛选
      filterData(){
        this.chartLoading = true;
        this.active = null;
        !this.filter.startVal && !this.filter.endVal ? this.searchType = 'day' : void 0; // 当未填写自定义时间时 重置为day
        this.getChartData();
      },
      // 点击切换绘制
    selectTime(index){
      this.chartLoading = true;
      this.resetData(); // 重置
      this.chart0.clear(); // 清除
      this.active = index;
      if(this.active == 0){
        this.searchType = 'day';
      }else if(this.active == 1){
        this.searchType = 'week';
      }else if(this.active == 2){
        this.searchType = 'month';
      }
      this.getChartData();
    },
      // 加载图表信息
      getChartData(){
        if(this.info.devType == 'camera'){
          this.getCameraChartData();
        }else{
          this.getSwjlChartData();
        }

      
    },
    getSwjlChartData(){
      return new Promise(resolve => {
        this.$api.SWJL.batChartsData({
          projectCode:this.projectCode,
          devCode:this.info.devCode,
          beginTime:this.filter.startVal || void 0,
          endTime:this.filter.endVal || void 0,
          searchType:this.filter.startVal || this.filter.endVal ? void 0 : this.searchType
        }).then( d => {
            
           let arrBat = [];
           for(let i=0;i<d.time.length;i++){
            arrBat.push({
                date:d.time[i].substring(0,d.time[i].lastIndexOf(':')),
                value:parseFloat(d.battery[i])/1000 ,
                name:'电池电压'
            });
           }
          this.chartData = arrBat;
          this.chartLoading = false;
          this.chartShow();
          resolve();
        })
      })
    },
    getCameraChartData(){
      return new Promise(resolve => {
        this.$api.SWJL.cameraBatChartsData({
          projectCode:this.projectCode,
          devCode:this.info.devCode,
          beginTime:this.filter.startVal || void 0,
          endTime:this.filter.endVal || void 0,
          searchType:this.filter.startVal || this.filter.endVal ? void 0 : this.searchType
        }).then( d => {
            
           let arrBat = [];
           for(let i=0;i<d.time.length;i++){
            arrBat.push({
                date:d.time[i].substring(0,d.time[i].lastIndexOf(':')),
                value:parseFloat(d.battery[i]) ,
                name:'电池电压'
            });
           }
          this.chartData = arrBat;
          this.chartLoading = false;
          this.chartShow();
          resolve();
        })
      })
    },
// 绘制图表
chartShow() {
      const allVal = this.chartData.map( v => v.value);
      const maxVal = Math.max(...allVal);

      this.$nextTick(() => {
        // 水位变化
        this.chart0 = new F2.Chart({
          id: "chartBattery",
          pixelRatio: window.devicePixelRatio,
        });
        this.chart0.source(this.chartData);
        this.chart0.scale("date", {
          type: "timeCat",
          mask: "MM-DD HH:mm",
          tickCount: 4,
          range: [0, 1]
        });

        const otherOpt = {
          tickCount: 6,
          min:2,
          max:5,
        }
        let waterOpt;
        if(maxVal >0 && maxVal <= 5){
          waterOpt = {
            min:0,
            max:5,
            tickInterval:1  // 数值范围10
          }
        }else if(maxVal > 6 && maxVal <= 50){
          waterOpt = {
            tickCount: 6,
            min:0,
            tickInterval:10  // 数值范围10
          }
        }else{
          waterOpt = {
            tickCount: 6,
            min:0,
          }
        }
      //  const optionValue = this.currentScene === 'water' ? waterOpt : otherOpt;
      const optionValue = otherOpt;
        this.chart0.scale("value", optionValue);
        this.chart0.axis("date", {
          label: function label(text, index, total) {
            const textCfg = {};
            if (index === 0) {
              textCfg.textAlign = "left";
            } else if (index === total - 1) {
              textCfg.textAlign = "right";
            }
            return textCfg;
          },
        });
        this.chart0.tooltip({
          custom: true, // 自定义 tooltip 内容框
          showXTip: true, // 是否展示 X 轴的辅助信息
          xTip: (obj)=>{
            return obj
          },
          onChange: (obj) => {
            const legend = this.chart0.get('legendController').legends.top[0];
            const tooltipItems = obj.items;
            const legendItems = legend.items;
            const map = {};
            legendItems.forEach((item)=>{
              map[item.name] = item;
            });
            tooltipItems.forEach((item)=> {
              const name = item.name;
              const value = item.value;
              if (map[name]) {
                map[name].value = value + ' v';
              }
            });
            legend.setItems(Object.values(map));
          },
          onHide:() => {
            const legend = this.chart0.get('legendController').legends.top[0];
            legend.setItems(this.chart0.getLegendItems().country);
          }
        });
        this.chart0
          .line({
            connectNulls: true, // 将空数据连接
          })
          .shape("smooth")
          .position("date*value").color('name')
        this.chart0.render();
      });
    },
    },
    created(){
      this.getBaseInfo();
      this.projectCode = window.sessionStorage.getItem("projectCode");
     // this.getBaseInfo();
    },
    mounted(){
    //   var dateTime = new Date();
    //   this.filter.endVal = moment(dateTime).format("yyyy-MM-DD HH:mm:ss");
    //   var dateTime1 = dateTime.setDate(dateTime.getDate()-1);
    //   dateTime1=new Date(dateTime1);
    //   this.filter.startVal = moment(dateTime1).format("yyyy-MM-DD HH:mm:ss");
      this.getChartData();
    }
  }
  </script>
  <style lang="scss" scoped>

.nav-char-custom{
  padding: 4%;
  display: flex;
  justify-content: space-around;
  .fa{padding-right: 4px;}
  .wid_3{width: 30%;}
  .block{
    line-height: 2;
    padding: 2% 4%;
    border-radius: 10px;
    color: #585858;
    box-shadow: 1px 1px 5px #dadada;
    font-weight: bold;
    margin-right: 2%;
    text-align: center;
    font-size: 0.9rem;
  }
  .active{
    background:linear-gradient(#99d2e6 0%, #2fd4bd 85%);
    color: whitesmoke;
  }
}
#chartBattery{
  width: 100%;
  height: 15rem;
}
.filter{
  text-align: left;
  .text-center {
    text-align: center;
    button {
      padding: 0 20px;
      margin-top: 5%;
    }
  }
}
#flowingWater{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  box-sizing: border-box;
}
  </style>